import React from 'react'
import Navigation from './Navigation'

const MainHeader = ({ string, changeLanguage }) => {
  return (
    <header>
      <Navigation string={string} changeLanguage={changeLanguage} />
    </header>
  )
}

export default MainHeader