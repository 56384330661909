import React from 'react'

import styles from './Footer.module.css'

const Footer = ({ string }) => {
  return (
    <footer
      className={
        styles.myFooter +
        ' text-center position-absolute bottom-0 start-0 end-0 py-2'
      }
    >
      <div className=" d-flex justify-content-center align-items-center gap-1">
        <div className={styles.facebook + ' ' + styles.icon}>
          <a
            href="https://www.facebook.com/groups/211316204754328/"
            target="_blank"
            rel="noreferrer"
          >
            fb
          </a>
        </div>
        <div className={styles.instagram + ' ' + styles.icon}>
          <a
            href="https://www.instagram.com/translationsandproofreading/"
            target="_blank"
            rel="noreferrer"
          >
            in
          </a>
        </div>
        <div className={styles.viber + ' ' + styles.icon}>
          <a href="mailto:translationsproofreading@yahoo.com">vb</a>
        </div>
      </div>
      <small>
        &copy; {string[0]}
        <a target="_blank" rel="noreferrer" href="https://webdevelopermaxi.eu.org">
          Maxi
        </a>
        {string[1]}
      </small>
    </footer>
  )
}

export default Footer
