import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import LocalizedStrings from 'react-localization'

import { data } from './component/data/data'
import MainHeader from './component/MainHeader/MainHeader'
import Home from './component/Home/Home'
import AboutMe from './component/AboutMe/AboutMe'
import Prices from './component/Prices/Prices'
import Contact from './component/Contact/Contact'
import Footer from './component/Footer/Footer'

function App() {
  
  let strings = new LocalizedStrings({ data })
  const [language, setLanguage] = useState('en')
  const [allStrings] = useState(strings)

  const changeLanguage = () => {
    setLanguage(language === 'en' ? 'sr' : 'en')
  }
  
  return (
    <div className="container">
      <MainHeader
        string={allStrings[language].navigation}
        changeLanguage={changeLanguage}
      />
      <Routes>
        <Route path="/" element={<Home string={allStrings[language].home} />} />
        <Route
          path="about"
          element={<AboutMe string={allStrings[language].about} />}
        />
        <Route
          path="prices"
          element={<Prices string={allStrings[language].prices} />}
        />
        <Route
          path="contact"
          element={<Contact string={allStrings[language].contact} />}
        />
      </Routes>
      <Footer string={allStrings[language].footer} />
    </div>
  )
}

export default App
