import React from 'react'

const Contact = ({ string }) => {
  return (
    <main className="text-center">
      <section>
        <h1 className="my-5 fs-4">{string[0]}</h1>
        <a href="mailto:translationsproofreading@yahoo.com">
          E-mail: translationsproofreading@yahoo.com
        </a>
        <br />
        <a href="https://www.facebook.com/groups/211316204754328/">Facebook</a>
        <br />
        <a href="https://www.instagram.com/translationsandproofreading/">
          Instagram
        </a>
      </section>
    </main>
  )
}

export default Contact