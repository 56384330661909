import React from 'react'

const Home = ({ string }) => {
  return (
    <main className="text-center">
      <section>
        <h1 className="my-5 fs-4">{string[0]}</h1>
        <h6 className="my-5">{string[1]}</h6>
        <p>{string[2]}</p>
        <p>{string[3]}</p>
        <p>{string[4]}</p>
        <p>{string[5]}</p>
        <p>{string[6]}</p>
      </section>
    </main>
  )
}

export default Home