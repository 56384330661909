import React from 'react'

const Prices = ({ string }) => {
  return (
    <main className="text-center">
      <section>
        <h1 className="my-5 fs-4">{string[0]}</h1>
        <h6 className="my-5">{string[1]}</h6>
        <h6 className="my-5">{string[2]}</h6>
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover align-middle">
            <thead>
              <tr>
                <th></th>
                <th>{string[3]}</th>
                <th>{string[4]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{string[5]}</td>
                <td>5-10 €</td>
                <td>8-12 €</td>
              </tr>
              <tr>
                <td>{string[6]}</td>
                <td>5-10 €</td>
                <td>8-12 €</td>
              </tr>
              <tr>
                <td>{string[7]}</td>
                <td>5-10 €</td>
                <td>8-10 €</td>
              </tr>
              <tr>
                <td>{string[8]}</td>
                <td>5-10 €</td>
                <td>8-10 €</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h6 className="mt-5">{string[9]}</h6>
        <h6 className="mb-5">{string[10]}</h6>
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover align-middle">
            <tbody>
              <tr>
                <td>{string[11]}</td>
                <td className='priceTd'>4-8 €</td>
              </tr>
              <tr>
                <td>{string[12]}</td>
                <td className='priceTd'>4-8 €</td>
              </tr>
              <tr>
                <td>{string[13]}</td>
                <td className='priceTd'>4-8 €</td>
              </tr>
              <tr>
                <td>{string[14]}</td>
                <td className='priceTd'>3-5 €</td>
              </tr>
            </tbody>
          </table>
          <h6 className="my-5">{string[15]}</h6>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover align-middle">
            <tbody>
              <tr>
                <td>{string[16]}</td>
                <td className='priceTd'>5-9 €</td>
              </tr>
              <tr>
                <td>{string[17]}</td>
                <td className='priceTd'>5-9 €</td>
              </tr>
              <tr>
                <td>{string[18]}</td>
                <td className='priceTd'>5-9 €</td>
              </tr>
              <tr>
                <td>{string[19]}</td>
                <td className='priceTd'>4-6 €</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h6 className="my-5">{string[20]}</h6>
          <p>{string[21]}</p>
          <p>{string[22]}</p>
          <br />
          <p>{string[23]}</p>
          <br />
        </div>
      </section>
    </main>
  )
}

export default Prices