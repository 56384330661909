import React from 'react'

const AboutMe = ({ string }) => {
  return (
    <main className="text-center">
      <section>
        <h1 className="my-5 fs-4">{string[0]}</h1>
        <p>{string[1]}</p>
        <p>{string[2]}</p>
        <p>{string[3]}</p>
        <p>{string[4]}</p>
      </section>
    </main>
  )
}

export default AboutMe