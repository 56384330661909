import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import styles from './Navigation.module.css'
import logo from '../../assets/logo.png'

const Navigation = ({ string, changeLanguage }) => {

  return (
    <nav className={styles.navbar + ' navbar navbar-expand-lg'}>
      <div className="container-fluid">
        <Link to='/' className="navbar-brand">
          <img src={logo} alt="Logo" width="50" height="50" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse d-lg-flex justify-content-lg-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  'nav-link ' + (isActive ? 'active' : '')
                }
                aria-current="page"
              >
                {string[0]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="about"
                className={({ isActive }) =>
                  'nav-link ' + (isActive ? 'active' : '')
                }
              >
                {string[1]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="prices"
                className={({ isActive }) =>
                  'nav-link ' + (isActive ? 'active' : '')
                }
              >
                {string[2]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="contact"
                className={({ isActive }) =>
                  'nav-link ' + (isActive ? 'active' : '')
                }
              >
                {string[3]}
              </NavLink>
            </li>
          </ul>
          <div id="changeLanguage">
            <button onClick={()=>{changeLanguage()}} id="changeLanguageBtn">
              {string[4]}
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation