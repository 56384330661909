const data = {
  en: {
    navigation: [`Home`, `About`, `Prices`, `Contact`, `SR`],
    home: [
      `Academic translations | proofreading`,
      `Your thoughts and words will spred clearly after we shape them right.`,
      `If you need to have your article, dissertation, or term paper translated, edited or proofread, we are here to do it for you.`,
      `We can help you with admissions essay or business correspondence.`,
      `Academic papers translation services from Bosnian, Croatian, Montenegrian and Serbian to English.`,
      `Proofreading of academic papers in Serbian and English Content writing in Serbian and English.`,
    ],
    about: [
      `About`,
      `Hello, I am Sonja Simon.`,
      `I am translating and proofreading academic and other texts. It takes a professional to compose a good CV or a popular science article, so I do it.`,
      `You can also rely on me when your correspondence is in question.`,
    ],
    prices: [
      `Prices`,
      `TRANSLATION`,
      `Prices of translations are per Word page , font 11, about 1800 characters.`,
      `Translation from English to Serbian language`,
      `Translation from Serbian to English language`,
      `term papers and academic works`,
      `academic papers or articles in the fields of psychology, sociology, pedagogy, history methodology, didactics`,
      `literary works, journal articles, popular science texts, music, marketing, essay writing, web content, brochures, manuals`,
      `CV, business or private correspondence`,
      `Proofreading`,
      `Proofreading price for texts in Serbian can vary according to the kind of it, volume and deadline.`,
      `term papers and academic works`,
      `psychology, sociology, pedagogy, methodology, didactics, history`,
      `web content, brochures, manuals`,
      `CV, business or private correspondence`,
      `Proofreading price for texts in English can vary according to the kind of it, volume and deadline.`,
      `term papers and academic works`,
      `psychology, sociology, pedagogy, history methodology, didactics`,
      `literary works, journal articles, popular science texts, music, marketing, essay writing, web content, brochures, manuals`,
      `CV, business or private correspondence`,
      `Content writing`,
      `Content writing in Serbian language costs 10 €.`,
      `Content writing in English language costs 15 €.`,
      `If in hurry, it will induce 10% extra charge.`,
    ],
    contact: [`Contact`],
    footer: [`2023 Academic Translations by `, `. All Rights Reserved.`],
  },
  sr: {
    navigation: [`Početna`, `O meni`, `Cene`, `Kontakt`, `EN`],
    home: [
      `Akademsko prevođenje | lektorisanje`,
      `Vaše misli i reči će daleko stići pošto ih mi uobličimo.`,
      `Ukoliko vam je potrebno da vam se prevede, napiše ili lektoriše reportaža, doktorandski ili seminarski rad, tu smo da to uradimo za vas.`,
      `Možemo vam pomoći i u poslovnoj prepisci.`,
      `Nudimo usluge prevoda akademskih radova s bošnjačkog, hrvatskog, crnogorskog i srpskog jezika.`,
      `Lektorišemo akademske radove na srpskom i engleskom jeziku. Pišemo tekstove na srpskom i engleskom jeziku.`,
    ],
    about: [
      `O meni`,
      `Poštovanje,  ja sam Sonja Šimon.`,
      `Prevodim i lektorišem akademske i ostale tekstove. Samo profesionalac može da napiše dobar CV ili članak na naučno-popularni način. Tako to i radim.`,
      `Možete imati poverenja u mene i kad je vaša prepiska  u pitanju.`,
    ],
    prices: [
      `Cene`,
      `PREVOD`,
      `Cene prevoda po strani se odnose na Word format, veličine fonta 11, oko 1800 karaktera.`,
      `Prevod sa engleskog jezika na srpski`,
      `Prevod sa srpskog jezika na engleski`,
      `seminarski i akademski radovi`,
      `akademski radovi i tekstovi iz oblasti psihologije, sociologije, pedagogije, istorije, metodike i didaktike`,
      `Književni radovi, reportaže, naučno-popularni tekstovi, muzika, marketing, pisanje eseja, tekstova za veb strane, brošure, uputstva za upotrebu`,
      `CV, poslovna i lična prepiska`,
      `Lektura i korektura`,
      `Cene korigovanja tekstova na srpskom jeziku mogu varirati spram vrste, obima i rokova.`,
      `seminarski i akademski radovi`,
      `akademski radovi i tekstovi iz psihologije, sociologije, pedagogije, istorije, metodike i didaktike`,
      `tekstovi za veb strane, brošure, uputstva za upotrebu`,
      `CV, poslovna i lična prepiska`,
      `Cene korigovanja tekstova na engleskom jeziku mogu varirati spram vrste, obima i rokova.`,
      `seminarski i akademski radovi`,
      `akademski radovi i tekstovi iz psihologije, sociologije, pedagogije, istorije, metodike i didaktike`,
      `Književni radovi, reportaže, naučno-popularni tekstovi, muzika, marketing, pisanje eseja, tekstova za veb strane, brošure, uputstva za upotrebu`,
      `CV, poslovna i lična prepiska`,
      `Pisanje tesktova`,
      `Pisanje tesktova na srpskom jeziku staje 10 €.`,
      `Pisanje tesktova na engleskom jeziku staje 15 €. `,
      `Hitnost povećava cenu za 10%.`,
    ],
    contact: [`Kontakt`],
    footer: [`2023 Akademsko prevođenje kodirao `, `. Sva prava zadržana.`],
  },
}
export { data }
